import { useState, useEffect, useRef } from "react";
import gsap from "gsap";

export default function useToggleModal() {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const triggerOpenRef = useRef<HTMLButtonElement | null>(null);
    const triggerCloseRef = useRef<HTMLButtonElement | null>(null);
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
        gsap.to(modalRef.current, { scale: 1, opacity: 1, duration: 0.5, ease: "power2.inOut", display: "flex" });
        gsap.to(triggerOpenRef.current, { opacity: 0, pointerEvents: "none" });
        gsap.to(triggerCloseRef.current, { opacity: 1, pointerEvents: "auto" });
    };

    const closeModal = () => {
        setModalOpen(false);
        gsap.to(modalRef.current, { scale: 0.9, opacity: 0, duration: 0.5, ease: "power2.inOut", display: "none" });
        gsap.to(triggerOpenRef.current, { opacity: 1, pointerEvents: "auto" });
        gsap.to(triggerCloseRef.current, { opacity: 0, pointerEvents: "none" });
    };

    const toggleModal = (e: React.MouseEvent) => {
        e.stopPropagation();
        modalOpen ? closeModal() : openModal();
    };

    useEffect(() => {
        const handleResize = () => {
            closeModal();
        };

        const handleKeyDown = (event: KeyboardEvent) => {
            if (modalOpen && event.key === "Escape") closeModal();
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [modalOpen]);

    return { modalRef, triggerOpenRef, triggerCloseRef, modalOpen, toggleModal };
}
