import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from 'gsap';
import '../../styles/footer.scss';

gsap.registerPlugin(ScrollTrigger);

export default function Footer() {
    const footerContainer = useRef(null);

    useGSAP(
        () => {
            const tl = gsap.timeline({ ease: 'power2.inOut' });

            tl
			.to('.footer-copy', {
				opacity: 1,
				duration: 5,
				scrollTrigger: {
					trigger: footerContainer.current,
					start: 'top top+=95%',
					end: 'bottom bottom',
					scrub: true
				}
			})

        }, { scope: footerContainer }
    );

    return (
        <footer className="footer" ref={footerContainer}>
            <p className="footer-copy">&copy; {`${new Date().getFullYear()} Level.  All rights reserved.`} <span></span> A Publicis Groupe Company.</p>
        </footer>
    );
}
