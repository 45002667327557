import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Button from "../Button/Button";
import "../../styles/nav.scss";
import { generateMailtoLink } from "../../utils/url/generateMailtoLink";
import { careerExternal } from "../../utils/url/careers";
import useToggleModal from "../../hooks/useToggleModal";

export default function Nav() {
    const navContainer = useRef<HTMLElement | null>(null);
    const { modalRef, triggerOpenRef, triggerCloseRef, modalOpen, toggleModal } = useToggleModal();

    useGSAP(() => {
        gsap.set([navContainer.current, ".nav-logo"], { autoAlpha: 0, y: "-20%" });
        gsap.to(navContainer.current, { autoAlpha: 1, y: 0, delay: 0.5 });
        gsap.to(".nav-logo", { autoAlpha: 1, y: 0, delay: 0.7 });
        gsap.set([navContainer.current, ".nav-logo"], { clearProps: "all" });
    }, { scope: navContainer });

    return (
        <nav className="nav" ref={navContainer}>
            <div className="nav-header">
                <img src="/img/level.svg" className="nav-logo" alt="The Level logo" />
                
                <div className="nav-links">
                    <a href={careerExternal()} className="nav-link link">Careers</a>
                    <Button href={generateMailtoLink()} copy="Let's talk" className="nav-link button" />
                </div>

                <div className="nav-buttons">
                    <button className="nav-toggle" onClick={toggleModal} aria-expanded={modalOpen} ref={triggerOpenRef}>
                        <img src="/img/nav-icon.svg" alt="Menu" className="nav-icon nav-icon-burger" />
                    </button>
                    <button className="nav-toggle close" onClick={toggleModal} aria-expanded={!modalOpen} ref={triggerCloseRef}>
                        <img src="/img/close-icon.svg" alt="Close" className="nav-icon nav-icon-close" />
                    </button>
                </div>
            </div>


            <div className={`nav-modal ${modalOpen ? "open" : ""}`} ref={modalRef}>
                <div className="nav-modal-content">
                    <a href={careerExternal()} className="nav-link link careers">Careers</a>
                    <Button href={generateMailtoLink()} copy="Let's talk" className="nav-link button mail" />
                </div>
            </div>
        </nav>
    );
}
