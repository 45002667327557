// mail:to link is used in three spots, Nav and Forbidden components
// currently, all use the same copy

const mailToSubject = 'Forbidden fruit? Tell me more.';
const mailToBody = 'Hey, Level — Show me the work samples! I can keep a secret.';

export function generateMailtoLink(subject = mailToSubject, body = mailToBody) {
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);
    return `mailto:contact@level-studios.com?subject=${encodedSubject}&body=${encodedBody}`;
}