// Stylesheet import
import './styles/reset.scss';
import './styles/typography.scss';
import './styles/main.scss';

// External imports
import { createRoot } from 'react-dom/client';

// Internal imports
import App from './App.tsx';

// Initialize the root element
const root = createRoot(document.getElementById('root')!);

// Create a root and render the App component
root.render(
    <App />
);
