import { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from 'react';

// Define the context value type
interface SplashScreenContextType {
  isSplashScreenCompleted: boolean;
  setSplashScreenCompleted: Dispatch<SetStateAction<boolean>>;
}

// Create the Splash Screen Context with a default value
const SplashScreenContext = createContext<SplashScreenContextType | undefined>(undefined);

// Define the props type for the provider
interface SplashScreenProviderProps {
  children: ReactNode;
}

// Create a Provider Component
export function SplashScreenProvider({ children }: SplashScreenProviderProps) {
  const [isSplashScreenCompleted, setSplashScreenCompleted] = useState<boolean>(false);

  return (
    <SplashScreenContext.Provider value={{ isSplashScreenCompleted, setSplashScreenCompleted }}>
      {children}
    </SplashScreenContext.Provider>
  );
}

// Custom Hook to use the Splash Screen Context
export function useSplashScreen(): SplashScreenContextType {
  const context = useContext(SplashScreenContext);
  if (context === undefined) {
    throw new Error('useSplashScreen must be used within a SplashScreenProvider');
  }
  return context;
}
