import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap/gsap-core';
import { useSplashScreen } from '../../contexts/SplashScreenContext';

import '../../styles/splash-screen.scss';

function SplashScreen() {
  const { setSplashScreenCompleted } = useSplashScreen();
  const splashScreenContainer = useRef(null);

  useGSAP(
    () => {
      const tl = gsap.timeline({ defaults: { ease: 'power2.inOut' } });

      tl
        .set('.splash-screen-logo', { autoAlpha: 0 })
        .set(['.splash-screen-copy-left', '.splash-screen-copy-right'], { autoAlpha: 0, y: '25px' })
        .to('.splash-screen-logo', { autoAlpha: 1, delay: 1, duration: 1.2 })
        .to('.splash-screen-copy-left', { autoAlpha: 1, y: 0, duration: .5 })
        .to('.splash-screen-copy-right', { autoAlpha: 1, y: 0, duration: .5 }, '<80%')
        .to(['.splash-screen-logo', '.splash-screen-copy-left', '.splash-screen-copy-right'], { autoAlpha: 0, delay: 1, onComplete: () => { setSplashScreenCompleted(true) } })
    },
    { scope: splashScreenContainer }
  );

  return (
    <div className="splash-screen" ref={splashScreenContainer}>
      <img src="/img/level.svg" className="splash-screen-logo" alt="Level" />
      <div className="splash-screen-copy-wrapper">
        <p className="splash-screen-copy splash-screen-copy-left">Digital Design.</p>
        <p className="splash-screen-copy splash-screen-copy-right">Elevated.</p>
      </div>
    </div>
  );
}

export default SplashScreen;
