import '../../styles/button.scss';

interface props {
	href?: string,
	copy?: string,
	className?: string,
	children?: React.ReactNode,
	hasIcon?: boolean
}

export default function Button({ href, copy, className, children, hasIcon }: props) {
	return(
		<a href={href} className={`button ${className ? className : ''} ${hasIcon ? 'button-icon' : ''}`}>
			{copy ? copy : 'Please provide copy as prop'}
			{children ? children : ''}
		</a>
	)
}