// External imports
import { Outlet } from 'react-router-dom';

// Shared components
import Nav from '../components/Nav/Nav';
import Footer from '../components/Footer/Footer';

// Component definition
export default function Main() {
    return (
        <>
            <Nav />
            <Outlet />
            <Footer />
        </>
    );
}
