// External imports
import { lazy } from 'react';

// Internal imports
import Main from '../layouts/Main';
import Route from '../interfaces/Route';

// Lazy load components
const Home = lazy(() => import('../pages/Home'));

const Routes: Route[] = [
    {
        path: '/',
        element: <Main />,
        children: [
            { path: '', element: <Home /> },
        ],
    },
];

export default Routes;
