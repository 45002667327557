// External imports
import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Internal imports
import { SplashScreenProvider, useSplashScreen } from './contexts/SplashScreenContext';
import SplashScreen from './components/SplashScreen/SplashScreen';

// Domain routes
import siteRoutes from './routes/routes';

// Interface imports
import Route from './interfaces/Route';

// Fallbacks - TODO: Update for appropriate theme
const NotFound = () => { return (<div>404 - Not Found</div>) };
const Loading = () => { return (<div></div>) };

// Combine all routes
const routes: Route[] = [
    ...siteRoutes,
    { path: '*', element: <NotFound /> }
];

// Create the router
const router = createBrowserRouter(routes);

function MainApp() {
    const { isSplashScreenCompleted } = useSplashScreen();

    return (
        <>
            {!isSplashScreenCompleted && <SplashScreen />}
            {isSplashScreenCompleted &&
                <Suspense fallback={<Loading />}>
                    <RouterProvider router={router} />
                </Suspense>}
        </>
    );
}

// Main App component
function App() {
    return (
        <SplashScreenProvider>
            <MainApp />
        </SplashScreenProvider>
    );
}

export default App;
